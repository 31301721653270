import { useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { z } from 'zod';
import { fbStore } from '../../lib/services/firebase-client';
import SectionHeader from './SectionHeader';

const Landlords = () => {
  const toast = useToast();
  const tx = useTxs();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    const result = z.string().email().safeParse(inputValue);
    if (!result.success) {
      toast.error('Please provide a valid email address');
    } else {
      setLoading(true);

      try {
        await setDoc(doc(fbStore, 'landlordsEmails', result.data), {});
        toast.success("Thank you, we can't wait to let you know!");
      } catch (error) {
        toast.error();
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <SectionHeader top={tx.top} title={tx.title} subtitle={tx.subtitle} />

      <div className="bg-white">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
          <div className="px-6 py-6 bg-primary-500 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
            <div className="xl:w-0 xl:flex-1">
              <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                {tx.card.title}
              </h2>
              <p className="mt-3 max-w-3xl text-lg leading-6 text-primary-50">{tx.card.body}</p>
            </div>

            <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
              <div className="sm:flex">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>

                <input
                  id="email-address"
                  name="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary focus:ring-white rounded-md"
                  placeholder={tx.card.fieldPlaceholder}
                  onChange={(ev) => setInputValue(ev.target.value)}
                />
                <button
                  type="button"
                  onClick={onSubmit}
                  disabled={isLoading}
                  className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0 disabled:opacity-50"
                >
                  {tx.card.button}
                </button>
              </div>

              <p className="mt-3 text-sm text-primary-50">
                {tx.card.privacyBody}{' '}
                <a
                  href="https://www.iubenda.com/privacy-policy/18654742/legal"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white font-medium underline"
                >
                  privacy policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useTxs = createTxs({
  en: {
    top: 'FOR LANDLORDS',
    title: 'Are you a property owner?',
    subtitle: "Get in touch with us and publish your property on Koolumbus. Let's go!",
    card: {
      title: "Want to be notified when it's ready?",
      body: "Leave us your email and we'll let you know when and how you can publish your property on Koolumbus!",
      fieldPlaceholder: 'Enter your email address',
      button: 'Notify me',
      privacyBody: 'We care about the protection of your data. Read our',
    },
  },
  it: {
    top: 'PER PROPRIETARI',
    title: 'Sei proprietario di un immobile?',
    subtitle: 'Mettiti in contatto con noi e pubblica la tua proprietà su Koolumbus.',
    card: {
      title: 'Vuoi essere avvisato quando è pronto?',
      body: 'Lasciaci la tua email e ti faremo sapere quando potrai pubblicare la tua proprietà sulla nostra piattaforma.',
      fieldPlaceholder: 'Inserisci il tuo indirizzo email',
      button: 'Avvisami',
      privacyBody: 'Abbiamo a cuore la protezione dei tuoi dati. Leggi la nostra',
    },
  },
});

export default Landlords;
