interface SectionHeaderProps {
  top: string;
  title: string;
  subtitle: string;
}

const SectionHeader: React.FC<React.PropsWithChildren<SectionHeaderProps>> = ({
  title,
  top,
  subtitle,
}) => {
  return (
    <div className="relative px-6">
      <h2 className="text-center text-base text-primary font-semibold tracking-wide uppercase">
        {top}
      </h2>
      <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-2">
        {title}
      </h2>
      <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">{subtitle}</p>
    </div>
  );
};

export default SectionHeader;
