import Head from 'next/head';
import React from 'react';
import { PageWithLayout } from '../../lib/types/page';
import Features from './Features';
import Hero from './Hero';
import Landlords from './Landlords';
import TopLocations from './TopLocations';
import TopProperties from './TopProperties';

const HomePage: PageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Koolumbus | Find your perfect place</title>
      </Head>

      <Hero />

      <section id="features" className="pt-40 lg:pt-24 bg-gray-50">
        <Features />
      </section>

      <section id="top-properties" className="mt-24">
        <TopLocations />
      </section>

      <section id="locations">
        <TopProperties />
      </section>

      <section id="landlords" className="mt-24 sm:mt-28">
        <Landlords />
      </section>
    </>
  );
};

export default HomePage;
