import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import { useSearchState } from '../../../lib/state/search-state.store';

export type HomeFormType = 'search' | 'auction';

interface HomeFormSwitchProps {}

const HomeFormSwitch: React.FC<React.PropsWithChildren<HomeFormSwitchProps>> = () => {
  const tx = useTxs();
  const selected = useSearchState((store) => store.meta.homeFormType);
  const setMeta = useSearchState((store) => store.setMeta);
  const isSearch = selected === 'search';

  const onToggle = () => {
    setMeta({ homeFormType: selected === 'auction' ? 'search' : 'auction' });
  };

  return (
    <div className="flex justify-center w-full">
      <div className="flex relative items-center bg-whiteAlpha-800 rounded-full shadow-lg h-[50px] p-1">
        <Option onClick={onToggle} checked={selected === 'search'} label={tx.search} />
        <Option onClick={onToggle} checked={selected === 'auction'} label={tx.auction}>
          <span className="flex-center ml-2 -mr-1.5 bg-primary py-[3px] px-1.5 text-white rounded-md text-[11px] z-10">
            NEW
          </span>
        </Option>

        <div
          className={clsx(
            'absolute top-1 bottom-1 left-1 w-32 lg:w-36 rounded-full shadow-md transition-transform duration-200 bg-white bg-opacity-90',
            !isSearch && 'translate-x-32 lg:translate-x-36',
          )}
        />
      </div>
    </div>
  );
};

interface OptionProps {
  checked: boolean;
  onClick: () => void;
  label: string;
}

const Option = ({ checked, label, onClick, children }: React.PropsWithChildren<OptionProps>) => {
  return (
    <div
      className={clsx(
        'flex-center h-full w-32 lg:w-36 cursor-pointer transition-colors',
        checked ? 'text-primary' : 'text-gray-700',
      )}
      onClick={onClick}
    >
      <span className="text-base font-semibold lg:text-lg z-10">{label}</span>
      {children}
    </div>
  );
};

const useTxs = createTxs({
  en: { search: 'Search', auction: 'Auction' },
  it: { search: 'Ricerca', auction: 'Asta' },
});

export default HomeFormSwitch;
