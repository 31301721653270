import React, { PropsWithChildren } from 'react';

interface HomeFormFieldProps {
  icon: JSX.Element;
}

const HomeFormField = ({ children, icon }: PropsWithChildren<HomeFormFieldProps>) => {
  return (
    <div className="flex items-center relative w-full h-16 lg:h-[72px] pl-5">
      {icon}
      {children}
    </div>
  );
};

export default HomeFormField;
