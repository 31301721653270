import {
  AdjustmentsIcon,
  ChatIcon,
  CheckIcon,
  HomeIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { createTxs } from '@koolumbus/web/utils';
import Image from 'next/legacy/image';
import { useMemo } from 'react';
import rightImg from '../../public/assets/images/feature-1.svg';
import leftImg from '../../public/assets/images/feature-2.svg';

const Features: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tx = useTxs();

  const auctionFeatures = useMemo(
    () => [
      {
        title: tx.auction.first.title,
        description: tx.auction.first.body,
        icon: AdjustmentsIcon,
      },
      {
        title: tx.auction.second.title,
        description: tx.auction.second.body,
        icon: ChatIcon,
      },
      {
        title: tx.auction.third.title,
        description: tx.auction.third.body,
        icon: CheckIcon,
      },
    ],
    [tx],
  );

  const searchFeatures = useMemo(
    () => [
      {
        name: tx.search.first.title,
        description: tx.search.first.body,
        icon: SearchIcon,
      },
      {
        name: tx.search.second.title,
        description: tx.search.second.body,
        icon: HomeIcon,
      },
    ],
    [tx],
  );

  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <h2 className="text-center text-base text-primary font-semibold tracking-wide uppercase">
            {tx.auctions}
          </h2>
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-2">
            {tx.title}
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">{tx.subtitle}</p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {tx.auction.title}
            </h3>

            <p className="mt-3 text-lg text-gray-500">{tx.auction.subtitle}</p>

            <dl className="mt-10 space-y-10">
              {auctionFeatures.map((item, i) => (
                <div key={i} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.title}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>

            <div className="p-16">
              <Image className="relative mx-auto" src={rightImg} alt="" objectFit="cover" />
            </div>
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                {tx.search.title}
              </h3>
              <p className="mt-3 text-lg text-gray-500">{tx.search.subtitle}</p>

              <dl className="mt-10 space-y-10">
                {searchFeatures.map((item, i) => (
                  <div key={i} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>

              <div className="p-16">
                <Image className="relative mx-auto rounded-xl shadow-sm" src={leftImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useTxs = createTxs({
  en: {
    auctions: 'Auctions',
    title: 'A new reservation system',
    subtitle:
      "We offer you two options to book with us and you decide the one that best suits your needs, let's start!",
    auction: {
      title: 'Create your new experience',
      subtitle:
        'This option offers you the possibility to set and customize the price of your accommodation according to your needs. By skipping all the steps and waiting, you will avoid long searches and you will book with the best conditions.',
      first: {
        title: 'Open your auction',
        body: `Choose where you want to go, when you want to travel and what is your budget and you
      are ready to go!`,
      },
      second: {
        title: 'Receive offers',
        body: 'Landlords will start to offer you amazing places, tailored to your needs, at the best possible price.',
      },
      third: {
        title: 'Pick your favorite',
        body: 'Accept your favorite offer and chat directly with the landlord to book your perfect place.',
      },
    },
    search: {
      title: 'The traditional method',
      subtitle:
        'With the traditional search you can obtain a wide range of accommodation opportunities designed for you.',
      first: {
        title: 'Search for a property',
        body: `You will simply have to filter your demands and needs and the search engine will find the perfect stay that suits you.`,
      },
      second: {
        title: 'Book now',
        body: 'In the next step you will already have a direct chat with the owner, without intermediaries or waiting. You will already have your reservation! Ready to enjoy it?',
      },
    },
  },
  it: {
    auctions: 'Aste',
    title: 'Un nuovo sistema di prenotazione',
    subtitle:
      'Ti offriamo due opzioni per prenotare con noi e tu decidi quella più adatta alle tue esigenze, cominciamo!',
    auction: {
      title: 'Crea la tua nuova esperienza',
      subtitle:
        'Questa opzione ti offre la possibilità di impostare e personalizzare il prezzo del tuo alloggio in base alle tue esigenze. Saltando tutti i passaggi e attese, eviterai lunghe ricerche e prenoterai con le migliori condizioni.',
      first: {
        title: 'Apri la tua asta',
        body: `Scegli dove e quando vorresti andare, il budget ideale per il tuo soggiorno, e la tua asta è pronta!`,
      },
      second: {
        title: 'Ricevi offerte',
        body: 'I proprietari di appartamenti vedranno la tua asta e cominceranno a fare le loro offerte',
      },
      third: {
        title: 'Scegli la tua preferita',
        body: "Accetta l'offerta che preferisci e sei pronto per chattare con il proprietario per finalizzare la prenotazione!",
      },
    },
    search: {
      title: 'Il metodo tradizionale',
      subtitle:
        'Con la ricerca tradizionale puoi ottenere una vasta gamma di opportunità di alloggio pensate per te.',
      first: {
        title: 'Cerca un immobile',
        body: `Dovrai semplicemente filtrare le tue richieste e necessità e il motore di ricerca troverà il soggiorno perfetto che fa per te.`,
      },
      second: {
        title: 'Prenota ora',
        body: 'Nel passaggio successivo avrai già una chat diretta con il proprietario, senza intermediari o attese. Avrai già la tua prenotazione! Sei pronto?',
      },
    },
  },
});

export default Features;
