import { CurrencyEuroIcon } from '@heroicons/react/outline';
import {
  formatBudget,
  MAX_AUCTION_PRICE_TO,
  MIN_AUCTION_PRICE_FROM,
  useTxs,
} from '@koolumbus/web/utils';
import clsx from 'clsx';
import React from 'react';
import { useSearchState } from '../../../lib/state/search-state.store';
import BudgetPicker from '../../common/budget-picker/BudgetPicker';
import HomeFormField from './HomeFormField';

type BudgetValue = [number, number];

const HomeFormBudgetField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { priceFrom, priceTo } = useSearchState((store) => store.state.budget);
  const setSearchState = useSearchState((store) => store.setState);

  const onChange = (value: BudgetValue) => {
    setSearchState({ budget: { priceFrom: value[0], priceTo: value[1] } });
  };

  const hasValue = priceFrom || priceTo;
  const minPriceFrom = MIN_AUCTION_PRICE_FROM;
  const maxPriceTo = MAX_AUCTION_PRICE_TO;

  const txs = useTxs({
    en: {
      label: 'Budget ( optional )',
      month: 'month',
      night: 'night',
    },
    it: {
      label: 'Budget ( opzionale )',
      month: 'mese',
      night: 'notte',
    },
  });

  return (
    <HomeFormField icon={<CurrencyEuroIcon className="text-gray-500 w-6 h-6 lg:w-7 lg:h-7" />}>
      <BudgetPicker
        value={[priceFrom ?? minPriceFrom, priceTo ?? maxPriceTo]}
        onChange={onChange}
        renderInput={({ openDialog }) => (
          <div className="ml-3 mr-4 w-full">
            <button
              onClick={openDialog}
              className={clsx(
                'flex w-full justify-start text-lg px-4 font-medium',
                hasValue ? 'text-gray-800' : 'text-gray-400',
              )}
            >
              {hasValue ? (
                <>
                  {formatBudget(priceFrom, priceTo)}
                  <span className="text-gray-400 ml-2">/ {txs.month}</span>
                </>
              ) : (
                txs.label
              )}
            </button>
          </div>
        )}
      />
    </HomeFormField>
  );
};

export default HomeFormBudgetField;
