import {
  getLocationDetails,
  getLocationGroupName,
  getLocationName,
  locations,
  locationsDetails,
  locationsGroups,
} from '@koolumbus/shared/utils';
import { useLocale } from '@koolumbus/web/utils';
import { Location } from '@prisma/client';
import clsx from 'clsx';
import { SelectHTMLAttributes } from 'react';

interface LocationSelectProps {
  value: Location | null;
  onChange: (value: Location | null) => void;
  placeholder: string;
  isClearable?: boolean;
  className?: string;
  showDisabledItems?: boolean;
}

const nullValue = '__ALL__';

const LocationSelect: React.FC<React.PropsWithChildren<LocationSelectProps>> = ({
  value,
  onChange,
  placeholder,
  isClearable = false,
  className,
  showDisabledItems = false,
}) => {
  const locale = useLocale();

  const onSelect: SelectHTMLAttributes<HTMLSelectElement>['onChange'] = (ev) => {
    onChange(ev.target.value === nullValue ? null : (ev.target.value as Location));
  };

  return (
    <select
      value={value ?? nullValue}
      id="location"
      name="location"
      onChange={onSelect}
      className={clsx(
        'w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md',
        value ? 'text-gray-800' : 'text-gray-400',
        className,
      )}
    >
      <option value={nullValue} disabled={!isClearable} hidden={!isClearable}>
        {placeholder}
      </option>

      {locationsGroups.map((group) => {
        const groupLocations = locations.filter(
          (loc) =>
            locationsDetails[loc].group === group &&
            (showDisabledItems || getLocationDetails(loc).active),
        );

        if (!groupLocations.length) {
          return null;
        }

        return (
          <optgroup key={group} label={getLocationGroupName(group, locale)}>
            {groupLocations.map((location) => {
              const details = getLocationDetails(location);
              const isActive = details.active ?? false;
              return (
                <option value={location} key={location} disabled={!isActive}>
                  {getLocationName(location, locale)}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </select>
  );
};

export default LocationSelect;
