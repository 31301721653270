// we can disable this because alt is provided by LocationCard component to its own image
/* eslint-disable jsx-a11y/alt-text */
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/legacy/image';
import Link from 'next/link';
import botticellaImg from '../../public/assets/images/top-properties/botticella.jpeg';
import morigiImg from '../../public/assets/images/top-properties/morigi.jpeg';
import odescalchiImg from '../../public/assets/images/top-properties/odescalchi.jpeg';
import thePlaceToBeImg from '../../public/assets/images/top-properties/the-place-to-be.jpeg';

const data: PropertyCardProps[] = [
  {
    id: '33f65e33-b65a-4f30-a4b0-02dc7c089ec3',
    name: 'Botticella Apartment',
    subtitle: 'Rome, Rione Trastevere',
    imageSrc: botticellaImg,
  },
  {
    id: 'ckoo6cdt2628430ks6sgaoibqb',
    name: 'Morigi Apartment',
    subtitle: 'Milano, Via Morigi',
    imageSrc: morigiImg,
  },
  {
    id: '6f442ba3-b06b-424d-92c1-44dbd7e7d86c',
    name: 'The Place To Be',
    subtitle: 'Rome, Via Dei Sabelli',
    imageSrc: thePlaceToBeImg,
  },
  {
    id: 'ckrdeeyni5780990qs6fpqd6plk',
    name: 'Palazzo Odescalchi',
    subtitle: 'Lago di Como',
    imageSrc: odescalchiImg,
  },
];

const TopProperties: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tx = useTxs();

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto">
        <div className="lg:text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-base text-primary font-semibold tracking-wide uppercase">
            {tx.topTitle}
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {tx.title}
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">{tx.subtitle}</p>
        </div>

        <div
          className={clsx(
            'flex xl:hidden no-scrollbar overflow-x-scroll overflow-y-auto rounded-md relative mt-10 xl:justify-center',
            'px-4 sm:px-6 lg:px-8',
          )}
        >
          <div className="flex space-x-2 md:space-x-4">
            {data.map((item) => (
              <PropertyCard
                key={item.id}
                id={item.id}
                name={item.name}
                subtitle={item.subtitle}
                imageSrc={item.imageSrc}
              />
            ))}
          </div>
        </div>

        <div
          className={clsx(
            'hidden xl:flex no-scrollbar overflow-x-scroll overflow-y-auto rounded-md relative mt-10 xl:justify-center',
            'px-4 sm:px-6 lg:px-8',
          )}
        >
          <div className="flex space-x-2 md:space-x-4">
            {data.slice(0, 4).map((item) => (
              <PropertyCard
                key={item.id}
                id={item.id}
                name={item.name}
                subtitle={item.subtitle}
                imageSrc={item.imageSrc}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface PropertyCardProps {
  id: string;
  name: string;
  subtitle: string;
  imageSrc: ImageProps['src'];
}

const PropertyCard: React.FC<React.PropsWithChildren<PropertyCardProps>> = ({
  id,
  name,
  subtitle,
  imageSrc,
}) => {
  return (
    <Link href={`/property/${id}`}>
      <div
        className={clsx(
          'relative h-80 min-w-[260px] rounded-md mt-2 mb-12 shadow-lg cursor-pointer',
          'transition-all',
          'hover:shadow-xl hover:scale-[1.04]',
        )}
      >
        <Image
          alt={name}
          src={imageSrc}
          className="h-full rounded-md w-auto"
          draggable={false}
          objectFit="cover"
          layout="fill"
          placeholder="blur"
        />

        <div
          aria-hidden="true"
          className="absolute inset-x-0 bottom-0 h-full rounded-md bg-gradient-to-t from-black opacity-50"
        />

        <div className="flex flex-col absolute w-full h-full justify-end left-0 bottom-0 py-3 px-4">
          <p className="text-white text-2xl font-semibold">{name}</p>
          <p className="text-white text-opacity-80 text-sm">{subtitle}</p>
        </div>
      </div>
    </Link>
  );
};

const useTxs = createTxs({
  en: {
    topTitle: 'TOP PROPERTIES',
    title: 'Some of our best accomodations',
    subtitle: 'We offer you the best properties where you will enjoy and feel like at home.',
  },
  it: {
    topTitle: 'TOP PROPRIETÀ',
    title: 'Alcuni dei nostri migliori alloggi',
    subtitle: 'Ti offriamo le migliori proprietà dove ti sentirai come a casa.',
  },
});

export default TopProperties;
