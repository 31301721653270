import { CalendarIcon } from '@heroicons/react/outline';
import { DatesSeparator } from '@koolumbus/web/ui';
import { useLocale, useTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import React from 'react';
import { formatDate } from '../../../lib/helpers/date';
import { useSearchState } from '../../../lib/state/search-state.store';
import DatesPicker from '../../common/dates-picker/DatesPicker';
import HomeFormField from './HomeFormField';

const HomeFormDatesField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const locale = useLocale();
  const setSearchState = useSearchState((store) => store.setState);
  const value = useSearchState((store) => store.state.dates);

  const txs = useTxs({
    en: {
      label: 'Stay dates',
    },
    it: {
      label: 'Date del soggiorno',
    },
  });

  return (
    <HomeFormField icon={<CalendarIcon className="text-gray-500 w-5 h-5 lg:w-6 lg:h-6" />}>
      <span className="nowrap truncate">
        <DatesPicker
          value={value}
          onChange={(value) => setSearchState({ dates: value })}
          renderInput={({ openPicker }) => (
            <div className="ml-3 mr-4 w-full">
              <button
                onClick={openPicker}
                className={clsx(
                  'flex w-full justify-start items-center text-lg px-4 font-medium',
                  value ? 'text-gray-800' : 'text-gray-400',
                )}
              >
                {value ? (
                  <div className="flex items-center">
                    <span className="flex-nowrap">{formatDate(value.dateFrom, locale)}</span>
                    <span className="px-3 text-gray-500">
                      <DatesSeparator />
                    </span>
                    <span className="flex-nowrap">{formatDate(value.dateTo, locale)}</span>
                  </div>
                ) : (
                  txs.label
                )}
              </button>
            </div>
          )}
        />
      </span>
    </HomeFormField>
  );
};

export default HomeFormDatesField;
