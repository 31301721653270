import { RangeSlider } from '@koolumbus/web/ui';
import { createTxs, MAX_AUCTION_PRICE_TO, MIN_AUCTION_PRICE_FROM } from '@koolumbus/web/utils';
import React from 'react';

interface BudgetInlinePickerProps {
  value: {
    priceFrom: number | null;
    priceTo: number | null;
  };
  onChange: (value: [number, number]) => void;
}

const BudgetInlinePicker: React.FC<React.PropsWithChildren<BudgetInlinePickerProps>> = ({
  value: { priceFrom, priceTo },
  onChange,
}) => {
  const tx = useTxs();

  const minPriceFrom = MIN_AUCTION_PRICE_FROM;
  const maxPriceTo = MAX_AUCTION_PRICE_TO;

  const sliderValue: [number, number] = [priceFrom ?? minPriceFrom, priceTo ?? maxPriceTo];

  return (
    <div className="w-full">
      <div className="flex w-full justify-between mb-8 px-1.5">
        <div>
          <p className="text-gray-400">{tx.minimum}</p>
          <p>
            <span className="text-gray-400 mr-1.5">€</span>
            {(priceFrom ?? minPriceFrom).toLocaleString()}
          </p>
        </div>

        <div className="text-right">
          <p className="text-gray-400">{tx.maximumm}</p>
          <p>
            <span className="text-gray-400 mr-1.5">€</span>
            {!priceTo || priceTo === maxPriceTo
              ? `${maxPriceTo.toLocaleString()}+`
              : priceTo.toLocaleString()}
          </p>
        </div>
      </div>

      <RangeSlider
        value={sliderValue}
        onChange={onChange}
        min={MIN_AUCTION_PRICE_FROM}
        max={MAX_AUCTION_PRICE_TO}
        step={50}
      />
    </div>
  );
};

const useTxs = createTxs({
  en: {
    minimum: 'Minimum',
    maximumm: 'Maximum',
  },
  it: {
    minimum: 'Minimo',
    maximumm: 'Massimo',
  },
});

export default BudgetInlinePicker;
