// we can disable this because alt is provided by LocationCard component to its own image
/* eslint-disable jsx-a11y/alt-text */
import { getLocationDetails, getLocationName } from '@koolumbus/shared/utils';
import { createTxs, useLocale } from '@koolumbus/web/utils';
import { Location } from '@prisma/client';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSearchState } from '../../lib/state/search-state.store';
import barcelonaImg from '../../public/assets/images/locations/barcelona.jpg';
import bolognaImg from '../../public/assets/images/locations/bologna.jpg';
import milanImg from '../../public/assets/images/locations/milan.jpg';
import romeImg from '../../public/assets/images/locations/rome.jpg';
import tenerifeImg from '../../public/assets/images/locations/tenerife.jpg';

const TopLocations: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tx = useTxs();

  const cardsProps: Partial<Record<Location, Omit<LocationCardProps, 'location'>>> = useMemo(
    () => ({
      ROME: {
        imageSrc: romeImg,
        subtitle: tx.stays(50),
      },
      MILAN: {
        imageSrc: milanImg,
        subtitle: tx.stays(100),
      },
      BOLOGNA: {
        imageSrc: bolognaImg,
        subtitle: tx.stays(25),
      },
      BARCELONA: {
        imageSrc: barcelonaImg,
        subtitle: tx.stays(10),
      },
      TENERIFE: {
        imageSrc: tenerifeImg,
        subtitle: tx.stays(10),
      },
    }),
    [tx],
  );

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto">
        <div className="lg:text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-base text-primary font-semibold tracking-wide uppercase">
            {tx.topTitle}
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {tx.title}
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">{tx.subtitle}</p>
        </div>

        <div
          className={clsx(
            'flex xl:hidden no-scrollbar overflow-x-scroll overflow-y-auto rounded-md relative mt-10 xl:justify-center',
            'px-4 sm:px-6 lg:px-8',
          )}
        >
          <div className="flex space-x-2 md:space-x-4">
            {Object.keys(cardsProps).map((key, i) => {
              const location = key as Location;
              const props = cardsProps[location]!;

              return (
                <LocationCard
                  key={i}
                  location={location}
                  subtitle={props.subtitle}
                  imageSrc={props.imageSrc}
                />
              );
            })}
          </div>
        </div>

        <div
          className={clsx(
            'hidden xl:flex no-scrollbar overflow-x-scroll overflow-y-auto rounded-md relative mt-10 xl:justify-center',
            'px-4 sm:px-6 lg:px-8',
          )}
        >
          <div className="flex space-x-2 md:space-x-4">
            {Object.keys(cardsProps)
              .slice(0, 4)
              .map((key, i) => {
                const location = key as Location;
                const props = cardsProps[location]!;

                return (
                  <LocationCard
                    key={i}
                    location={location}
                    subtitle={props.subtitle}
                    imageSrc={props.imageSrc}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

interface LocationCardProps {
  location: Location;
  subtitle: string;
  imageSrc: ImageProps['src'];
}

const LocationCard: React.FC<React.PropsWithChildren<LocationCardProps>> = ({
  location,
  subtitle,
  imageSrc,
}) => {
  const { active } = getLocationDetails(location);
  const locale = useLocale();
  const router = useRouter();

  const { setSearchMeta, setSearchState } = useSearchState((store) => ({
    setSearchState: store.setState,
    setSearchMeta: store.setMeta,
  }));

  const title = getLocationName(location, locale);

  const onClick = () => {
    if (active) {
      setSearchState({ location });
      setSearchMeta({ auctionFormInitialStep: 1 });
      router.push('/auctions/new');
    }
  };

  return (
    <div
      className={clsx(
        'relative h-80 min-w-[260px] rounded-md mt-2 mb-12 shadow-lg',
        active && 'cursor-pointer',
        'transition-all',
        'hover:shadow-xl hover:scale-[1.04]',
      )}
      onClick={onClick}
    >
      <Image
        alt={title}
        src={imageSrc}
        className="h-full rounded-md w-auto"
        draggable={false}
        objectFit="cover"
        layout="fill"
        placeholder="blur"
      />

      <div
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-full rounded-md bg-gradient-to-t from-black opacity-50"
      />

      <div className="flex flex-col absolute w-full h-full justify-end left-0 bottom-0 py-3 px-4">
        <p className="text-white text-3xl font-semibold">{title}</p>
        <p className="text-white text-opacity-80 text-sm">{subtitle}</p>
      </div>
    </div>
  );
};

const useTxs = createTxs({
  en: {
    stays: (count: number) => `${count}+ stays`,
    topTitle: 'TOP LOCATIONS',
    title: 'Hundreds of places across Europe',
    subtitle:
      'Here you can find some of the best locations that we offer you to start your new experience.',
  },
  it: {
    stays: (count) => `${count}+ alloggi`,
    topTitle: 'TOP LOCATIONS',
    title: 'Centinaia di posti in tutta Europa',
    subtitle:
      'Qui puoi trovare alcune delle migliori locations che ti offriamo per iniziare la tua nuova esperienza.',
  },
});

export default TopLocations;
