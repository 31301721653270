import { useDisclosure } from '@koolumbus/web/ui';
import { MAX_AUCTION_PRICE_TO, MIN_AUCTION_PRICE_FROM } from '@koolumbus/web/utils';
import React from 'react';
import BudgetDialog from './BudgetDialog';

interface BudgetPickerProps {
  value: [number, number];
  onChange: (value: [number, number]) => void;
  renderInput: (params: { openDialog: () => void }) => JSX.Element;
}

const BudgetPicker: React.FC<React.PropsWithChildren<BudgetPickerProps>> = ({
  value,
  onChange,
  renderInput,
}) => {
  const { isOpen: isDialogOpen, onClose: onCloseDialog, onOpen: onOpenDialog } = useDisclosure();

  const onReset = () => {
    onChange([MIN_AUCTION_PRICE_FROM, MAX_AUCTION_PRICE_TO]);
  };

  return (
    <>
      {renderInput({ openDialog: onOpenDialog })}

      <BudgetDialog
        initialValue={value}
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        onSubmit={onChange}
        onReset={onReset}
      />
    </>
  );
};

export default BudgetPicker;
