import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import React from 'react';
import heroBg from '../../public/assets/images/hero-bg.jpg';
import HomeForm from './home-form/HomeForm';
import HomeFormSwitch from './home-form/HomeFormSwitch';

interface HomeSearchProps {}

const Hero: React.FC<React.PropsWithChildren<HomeSearchProps>> = () => {
  return (
    <div
      className={clsx(
        'w-full min-h-[480px] relative',
        'after:block after:w-full after:h-full after:absolute after:bottom-0 after:bg-black after:bg-opacity-40 after:pointer-events-none',
      )}
    >
      <Background />

      <div
        className={clsx(
          'relative z-10 h-[490px] my-0 mx-[4%]',
          'sm:h-[500px] sm:mx-[14%]',
          'md:h-[520px] md:mx-[16%]',
          'lg:h-[560px] lg:max-w-5xl lg:mx-auto',
          'xl:h-[620px]',
        )}
      >
        <Transition
          appear={true}
          show={true}
          enter="transition-all duration-200 delay-200"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-5"
        >
          <Titles />
        </Transition>

        <div
          className={clsx(
            'flex flex-col justify-center absolute w-full space-y-6 md:space-y-8 lg:space-y-10',
            '-bottom-40 lg:-bottom-10',
          )}
        >
          <HomeFormSwitch />
          <HomeForm />
        </div>
      </div>
    </div>
  );
};

const Background: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full overflow-hidden">
      <div className="h-full w-full relative">
        <Image
          src={heroBg}
          alt="background"
          layout="fill"
          objectFit="cover"
          priority
          placeholder="blur"
        />
      </div>
    </div>
  );
};

const Titles: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className={clsx('flex flex-col justify-center h-[540px] pb-44', 'lg:h-[720px]')}>
      <div className="text-center space-y-2">
        <h3
          className={clsx(
            'text-white text-opacity-90 font-semibold uppercase tracking-wide',
            'lg:text-xl',
          )}
        >
          The <span className="font-bold sm:font-extrabold">fastest</span> and{' '}
          <span className="font-bold sm:font-extrabold">easiest</span> way to
        </h3>

        <h1 className="text-[44px] leading-[1] tracking-tight font-extrabold text-white md:text-5xl lg:text-6xl xl:text-7xl">
          Find your <span className="text-accent">perfect</span> place
        </h1>
      </div>
    </div>
  );
};

export default Hero;
