import { Button, Dialog } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import React, { useState } from 'react';
import BudgetInlinePicker from './BudgetInlinePicker';

interface BudgetDialogProps {
  isOpen: boolean;
  onClose: () => void;
  initialValue: [number, number];
  onSubmit: (value: [number, number]) => void;
  onReset: () => void;
}

const BudgetDialog: React.FC<React.PropsWithChildren<BudgetDialogProps>> = ({
  initialValue,
  onSubmit,
  onReset: handleReset,
  isOpen,
  onClose,
}) => {
  const tx = useTxs();
  const [internalValue, setInternalValue] = useState<[number, number]>(initialValue);

  const onConfirm = () => {
    onSubmit(internalValue);
    onClose();
  };

  const onReset = () => {
    handleReset();
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="bg-white px-5 pt-4 pb-4 sm:p-6 sm:pb-4 sm:pt-5">
        <div className="text-xl font-medium">{tx.title}</div>

        <div className="pb-12 pt-5">
          <BudgetInlinePicker
            value={{ priceFrom: internalValue[0], priceTo: internalValue[1] }}
            onChange={setInternalValue}
          />
        </div>
      </div>

      <div className="flex w-full bg-gray-50 px-4 py-3 sm:px-6 justify-between">
        <Button variant="outline" onClick={onReset}>
          Reset
        </Button>

        <div className="space-x-2">
          <Button variant="outline" onClick={onClose}>
            {tx.actions.cancel}
          </Button>

          <Button onClick={onConfirm}>{tx.actions.confirm}</Button>
        </div>
      </div>
    </Dialog>
  );
};

const useTxs = createTxs({
  en: {
    title: 'Budget per month',
    actions: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
  },
  it: {
    title: 'Budget per mese',
    actions: {
      cancel: 'Annulla',
      confirm: 'Conferma',
    },
  },
});

export default BudgetDialog;
