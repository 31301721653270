import { MaterialIcon, useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useSearchState } from '../../../lib/state/search-state.store';
import HomeFormBudgetField from './HomeFormBudgetField';
import HomeFormDatesField from './HomeFormDatesField';
import HomeFormLocationField from './HomeFormLocationField';

interface HomeFormProps {}

const HomeForm: React.FC<React.PropsWithChildren<HomeFormProps>> = () => {
  const router = useRouter();
  const toast = useToast();
  const tx = useTxs();
  const searchState = useSearchState((store) => store.state);
  const type = useSearchState((store) => store.meta.homeFormType);
  const setMeta = useSearchState((store) => store.setMeta);

  const onSubmit = () => {
    const { location, dates } = searchState;

    if (!location) {
      return toast.warning(tx.missingLocationWarn);
    }

    if (type === 'auction') {
      const step = typeof dates === 'undefined' ? 1 : 2;
      setMeta({ auctionFormInitialStep: step });
      router.push(`/auctions/new`);
    } else {
      router.push(`search/${location.toLowerCase()}`);
    }
  };

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-end w-full rounded-lg min-h-[76px] p-1 bg-white shadow-lg',
        'lg:flex-row lg:rounded-[44px]',
      )}
    >
      <HomeFormLocationField />

      <Divider />

      <HomeFormDatesField />

      <Divider />

      <HomeFormBudgetField />

      <button
        className={clsx(
          'flex-center rounded-lg min-w-full h-14 text-white bg-primary hover:bg-primary-600 border-0 text-lg shadow-md mt-2',
          'lg:rounded-[45px] lg:h-[78px] lg:mt-0 lg:text-xl lg:min-w-[78px] lg:pb-[6px]',
        )}
        onClick={onSubmit}
      >
        <MaterialIcon
          className="hidden lg:inline-block text-[26px] mt-[7px] ml-0.5"
          icon={type === 'auction' ? 'send' : 'search'}
        />
        <span className="lg:hidden">{tx.submit}</span>
      </button>
    </div>
  );
};

const Divider = () => (
  <div className={clsx('bg-gray-200 m-0 w-11/12 h-[1px]', 'lg:w-[1px] lg:h-[50px]')} />
);

const useTxs = createTxs({
  en: {
    missingLocationWarn: 'Please select your destination',
    submit: 'Next',
  },
  it: {
    missingLocationWarn: 'Seleziona la tua destinazione',
    submit: 'Continua',
  },
});

export default HomeForm;
