import { LocationMarkerIcon } from '@heroicons/react/outline';
import { useTxs } from '@koolumbus/web/utils';
import { Location } from '@prisma/client';
import React from 'react';
import { useSearchState } from '../../../lib/state/search-state.store';
import LocationSelect from '../../common/LocationSelect';
import HomeFormField from './HomeFormField';

const HomeFormLocationField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const value = useSearchState((store) => store.state.location);
  const setSearchState = useSearchState((store) => store.setState);

  const onChange = (val: Location | null) => {
    setSearchState({ location: val ?? undefined });
  };

  const txs = useTxs({
    en: {
      label: 'Destination',
    },
    it: {
      label: 'Destinazione',
    },
  });

  return (
    <HomeFormField icon={<LocationMarkerIcon className="text-gray-500 w-6 h-6 lg:w-7 lg:h-7" />}>
      <LocationSelect
        className="text-lg font-medium ml-4 mr-2 border-0 w-full focus:outline-none focus:ring-0 shadow-none"
        value={value ?? null}
        onChange={onChange}
        placeholder={txs.label}
      />
    </HomeFormField>
  );
};

export default HomeFormLocationField;
